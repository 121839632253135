import { render, staticRenderFns } from "./ProfilePreferencesEditor.vue?vue&type=template&id=4ba3ccbf&scoped=true"
import script from "./ProfilePreferencesEditor.vue?vue&type=script&lang=ts&setup=true"
export * from "./ProfilePreferencesEditor.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./ProfilePreferencesEditor.vue?vue&type=style&index=0&id=4ba3ccbf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ba3ccbf",
  null
  
)

export default component.exports