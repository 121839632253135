import { ContractStatus } from '@/models/Contracts';

const contractData: any[] = [
  {
    id: '1',
    partner: {
      userId: '001fallback',
      firstname: 'Lutz',
      lastname: 'Ursi'
    },
    project: {
      title: 'Test title 1'
    },
    status: ContractStatus.Open,
    lastChanged: '2023-12-04 22:13:42', // Heute
    read: false
  },
  {
    id: '2',
    partner: {
      userId: '002fallback',
      firstname: 'Marcel',
      lastname: 'Davis'
    },
    project: {
      title: 'Test title 2'
    },
    status: ContractStatus.Ended,
    lastChanged: '2023-11-04 22:13:42', // vor 1 Monat
    read: true
  },
  {
    id: '3',
    partner: {
      userId: '003fallback',
      firstname: 'Julian',
      lastname: 'Großwurz'
    },
    project: {
      title: 'Test title 3'
    },
    status: ContractStatus.Canceled,
    lastChanged: '2023-12-01 22:13:42', // vor 3 Tage
    read: true
  },
  {
    id: '4',
    partner: {
      userId: '004fallback',
      firstname: 'Marina',
      lastname: 'Tellinghusen'
    },
    project: {
      title: 'Test title 4'
    },
    status: ContractStatus.Signed,
    lastChanged: '2023-10-04 22:13:42', // vor 2 Monaten
    read: true
  },
  {
    id: '5',
    partner: {
      userId: '005fallback',
      firstname: 'Sabrina',
      lastname: 'Hauser'
    },
    project: {
      title: 'Test title qrwer porewfh eoprugh apofh apoenf apoenbf aposenbf apoenbf pou 5'
    },
    status: ContractStatus.Open,
    lastChanged: '2023-09-04 22:13:42', // vor 3 Monaten
    read: false
  },
  {
    id: '6',
    partner: {
      userId: '006fallback',
      firstname: 'Elif',
      lastname: 'Gökdemir'
    },
    project: {
      title: 'Test title 6'
    },
    status: ContractStatus.Ended,
    lastChanged: '2023-11-01 22:13:42', // vor 1 Monat und 3 Tage
    read: true
  },
  {
    id: '7',
    partner: {
      userId: '007fallback',
      firstname: 'Jack',
      lastname: 'Nasher'
    },
    project: {
      title: 'Test title 7'
    },
    status: ContractStatus.Signed,
    lastChanged: '2023-10-20 22:13:42', // vor 1 Monat und 14 Tage
    read: true
  },
  {
    id: '8',
    partner: {
      userId: '008fallback',
      firstname: 'Franz',
      lastname: 'Altentüdel'
    },
    project: {
      title: 'Test title 8'
    },
    status: ContractStatus.Open,
    lastChanged: '2023-12-02 22:13:42', // vor 2 Tage
    read: true
  },
  {
    id: '9',
    partner: {
      userId: '009fallback',
      firstname: 'Lisa',
      lastname: 'Grantl'
    },
    project: {
      title: 'Test title 9'
    },
    status: ContractStatus.Ended,
    lastChanged: '2023-11-29 22:13:42', // vor 5 Tage
    read: true
  },
  {
    id: '10',
    partner: {
      userId: '0010fallback',
      firstname: 'Jasemine',
      lastname: 'Sunderland'
    },
    project: {
      title: 'Test title 10'
    },
    status: ContractStatus.Canceled,
    lastChanged: '2023-11-10 22:13:42', // vor 8 Tage
    read: true
  },
  {
    id: '11',
    partner: {
      userId: '0011fallback',
      firstname: 'Marie',
      lastname: 'van der Donken van Onken ter Donken'
    },
    project: {
      title: 'Test title 11'
    },
    status: ContractStatus.Open,
    lastChanged: '2023-12-03 22:13:42', // vor 1 Tag
    read: true
  },
  {
    id: '12',
    partner: {
      userId: '0012fallback',
      firstname: 'Ted',
      lastname: 'Terror'
    },
    project: {
      title: 'Test title 12'
    },
    status: ContractStatus.Signed,
    lastChanged: '2023-10-30 22:13:42', // vor 1 Monat und 5 Tage
    read: true
  }
];

export default contractData;
