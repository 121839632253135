
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import BCXUserBanner from '@/components/molecules/BCXUserBanner.vue';
import useQuickInfo from '@/state/quickInfo';
import {
  computed, defineComponent, inject, nextTick, onMounted, ref, toRef, toRefs, watch, watchEffect
} from 'vue';
import { Editor } from '@toast-ui/vue-editor';
import ToastEditor from '@toast-ui/editor';
import { useStore } from 'vue2-helpers/vuex';
import { buttonSVGs, ButtonType } from '@/mixins/useToastUiEditorTools';
import useI18n from '@/mixins/useI18n';
import Chevron from '@/components/svg-components/Chevron.vue';
import Close from '@/components/svg-components/X.vue';
import { logDebug } from '@/utils/logger';
import BCXHTMLSanitizer from '@/utils/BCXHTMLSanitizer';
import useIsLeavingDirty from '@/utils/isLeavingDirty';

export default defineComponent({
  components: {
    BCXUserBanner,
    BCXAvatar,
    Editor,
    Chevron,
    Close,
  },
  injects: ['maxCreateMessageTextLength'],
  props: {
    replyToText: {
      type: String,
      default: '',
    },
    replyToUser: {
      type: Object,
      default: () => ({}),
    },
    messageText: {
      type: String,
      default: '',
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
    error: {
      type: String,
      default: '',
    },
  },
  emits: ['hide', 'post-message'],
  setup(props, { emit }) {
    const store = useStore();
    const { t, te } = useI18n();
    const isMobileLayout = computed(() => store.getters['page/isMobileLayout']);
    const user = computed(() => store.getters.user);
    const isUserAdmin = computed(() => (user.value.roles as Array<string>).includes('ADMIN'));
    const error = toRef(props, 'error');
    const errorMessageText = computed(() => {
      const errorKey = `forum.errors.${error.value}`;
      if (te(errorKey)) {
        return t(errorKey).toString();
      }
      return error.value;
    });
    const { beforeRenderEditor } = BCXHTMLSanitizer;

    const {
      isVisible, replyToUser
    } = toRefs(props);

    const maxCreateMessageTextLength: number = inject('maxCreateMessageTextLength') as number;

    const textAreaRowHeight = ref(18);
    const textAreaRows = ref(14);
    const editorHeight = computed(() => `${textAreaRows.value * textAreaRowHeight.value}px`);

    const addImageBlobHook = (blob: File, callback: any) => {
      /* if (blob.size < 1024 * 20) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        let base64FileString = '';
        reader.onload = () => {
          base64FileString = reader.result as string;
        };
        callback(`data:${blob.type};base64,${base64FileString}`, '');
      } */
      callback('', '');
    };

    const toastUiEditor = ref<ToastEditor>({} as ToastEditor);

    const charsLeft = computed(() => {
      if (toastUiEditor.value.getMarkdown) {
        return maxCreateMessageTextLength - (toastUiEditor.value?.getMarkdown().length ?? 0);
      }
      return maxCreateMessageTextLength;
    });

    watch(() => charsLeft.value, (newValue) => {
      if (newValue <= 0 && !isUserAdmin.value) {
        const truncatedText = toastUiEditor.value?.getMarkdown().substring(0, maxCreateMessageTextLength);
        toastUiEditor.value.setMarkdown(truncatedText);
      }
    });

    watch(() => isVisible.value, (value: boolean) => {
      if (value) {
        toastUiEditor.value.focus();
      }
    });

    const isTextAreaLarge = ref(false);

    watch(() => isTextAreaLarge.value, () => {
      toastUiEditor.value.focus();
    });

    const initialTextValue = ref('');

    onMounted(() => {
      initialTextValue.value = props.messageText;
    });

    const isReadyToSend = computed(() => {
      if (typeof toastUiEditor.value.getMarkdown === 'function') {
        return toastUiEditor.value.getMarkdown().length > 0;
      }
      return false;
    });

    const {
      isLeavingDirty, isLeavingMessage, onConfirm, isDirty
    } = useIsLeavingDirty();
    watchEffect(() => {
      if (toastUiEditor.value.getMarkdown) {
        isDirty.value = (toastUiEditor.value?.getMarkdown() !== initialTextValue.value && isReadyToSend.value);
      }
    });

    const postMessage = () => {
      let text = toastUiEditor.value.getHTML();
      if (!isUserAdmin.value) {
        logDebug(text.matchAll(/!\[.*\]\((?:data:.+;base64,.+|https?:\/\/.+|\/.+)\)/gi));
        text = text.replaceAll(/!\[.*\]\((?:data:.+;base64,.+|https?:\/\/.+|\/.+)\)/gi, '');
      }
      nextTick(() => {
        if (text.trim().length >= 1) {
          emit('post-message', BCXHTMLSanitizer.beforeSave(text));
          setTimeout(() => {
            toastUiEditor.value.setMarkdown('');
          }, 10);
        }
      });
    };

    const hideCreateMessage = () => {
      emit('hide');
    };

    const onKeyDown = (ev: KeyboardEvent | 'wysiwyg') => {
      if (typeof ev === 'object' && ev.type === 'keydown') {
        if ((ev.code === 'Enter') && ev.ctrlKey) {
          postMessage();
        } else if (ev.key === 'Escape') {
          hideCreateMessage();
        }
      }
    };

    const onLoad = (editor: ToastEditor) => {
      editor.getHTML();
      toastUiEditor.value = editor;
      nextTick(() => {
        if (isUserAdmin.value) {
          toastUiEditor.value.insertToolbarItem({ groupIndex: 0, itemIndex: 10 }, 'image');
        } else {
          toastUiEditor.value.addHook('addImageBlobHook', addImageBlobHook);
        }
        toastUiEditor.value.setHeight(editorHeight.value);
      });
    };

    const showReplyToUserQuickInfo = (ev: Event) => {
      const { showQuickInfo } = useQuickInfo();
      showQuickInfo(ev.target as HTMLElement, replyToUser.value);
    };

    const toggleTextAreaSize = () => {
      isTextAreaLarge.value = !isTextAreaLarge.value;
      textAreaRows.value = isTextAreaLarge.value ? 26 : 14;

      nextTick(() => {
        toastUiEditor.value.setHeight(editorHeight.value);
      });
    };

    const createButton = (which: ButtonType) => {
      const button = document.createElement('button');
      button.style.margin = '0';
      button.innerHTML = buttonSVGs[which];
      button.addEventListener('click', () => {
        if (toastUiEditor.value) toastUiEditor.value.exec(which);
      });
      return button;
    };

    return {
      t,
      isReadyToSend,
      editorHeight,
      editorOptions: {
        usageStatistics: false,
        hideModeSwitch: true,
        autofocus: false,
        customHTMLSanitizer: BCXHTMLSanitizer.beforeSave,
        toolbarItems: [
          ['bold', 'italic', 'quote', 'ul', 'ol', 'indent', 'outdent', 'link',
            {
              el: createButton('undo'),
              command: 'undo',
              tooltip: t('buttons.undo')
            },
            {
              el: createButton('redo'),
              command: 'redo',
              tooltip: t('buttons.redo')
            }]
        ],
      },
      isTextAreaLarge,
      user,
      isUserAdmin,
      isMobileLayout,
      maxCreateMessageTextLength,
      charsLeft,
      showReplyToUserQuickInfo,
      beforeRenderEditor,
      hideCreateMessage,
      postMessage,
      onKeyDown,
      onLoad,
      toggleTextAreaSize,
      isLeavingDirty,
      isLeavingMessage,
      onConfirm,
      errorMessageText,
    };
  }
});
