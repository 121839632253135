import { BaseUserWithRole } from '@/models/User';

export enum InvoiceStatus { // @todo: move to real model file when defined
  Open = 'open',
  Paid = 'paid',
  Overdue = 'overdue',
  New = 'new',
  Sent = 'sent',
  Draft = 'draft',
}

export interface Invoice { // @todo: move to real model file when defined
  id: string;
  user: BaseUserWithRole;
  reference: string;
  costCenter: string;
  due: string;
  status: InvoiceStatus;
}

export const fakeInvoices: Invoice[] = [
  {
    id: '1',
    user: {
      firstname: 'Nina',
      lastname: 'Mustermann',
      userId: 'fallback1'
    },
    reference: 'L qwer qwerara lara lara',
    costCenter: 'Costcenter 1',
    due: '2021-03-05',
    status: InvoiceStatus.Overdue,
  },
  {
    id: '2',
    user: {
      firstname: 'Lara',
      lastname: 'Tester',
      userId: 'fallback2'
    },
    reference: 'as qwer  dfasdf lara lara',
    costCenter: 'Costcenter 2',
    due: '2023-02-06',
    status: InvoiceStatus.Sent,
  },
  {
    id: '3',
    user: {
      firstname: 'Paulinschen',
      lastname: 'Asdf',
      userId: 'fallback3'
    },
    reference: 'asc  dasdcasdc lara lara',
    costCenter: 'Costcenter 3',
    due: '2025-11-21',
    status: InvoiceStatus.Paid,
  },
  {
    id: '4',
    user: {
      firstname: 'Adolf',
      lastname: 'Fodal',
      userId: 'fallback4'
    },
    reference: 'ascasdc qwr qweasdcasdc',
    costCenter: 'Costcenter 4',
    due: '2025-05-07',
    status: InvoiceStatus.Open,
  },
  {
    id: '5',
    user: {
      firstname: 'Ulf',
      lastname: 'Ulfinger',
      userId: 'fallback5'
    },
    reference: 'qwtqwer awefawef  aoehf apoewhfawpoehf aowue fdc',
    costCenter: 'Costcenter 5',
    due: '2024-01-06',
    status: InvoiceStatus.Draft,
  },
  {
    id: '6',
    user: {
      firstname: 'Nina',
      lastname: 'Mustermann',
      userId: 'fallback1'
    },
    reference: 'Lara l rqwer qwerara lara',
    costCenter: 'Costcenter 6',
    due: '2021-03-11',
    status: InvoiceStatus.Overdue,
  },
  {
    id: '7',
    user: {
      firstname: 'Lara',
      lastname: 'Tester',
      userId: 'fallback22'
    },
    reference: 'qwer qwerasdfasdf lara lara',
    costCenter: 'Costcenter 7',
    due: '2023-03-22',
    status: InvoiceStatus.Sent,
  },
  {
    id: '8',
    user: {
      firstname: 'Paulinschen',
      lastname: 'Asdf',
      userId: 'fallback23'
    },
    reference: '2345 23 5ascdasdcasdc lara lara',
    costCenter: 'Costcenter 8',
    due: '2025-11-11',
    status: InvoiceStatus.Paid,
  },
  {
    id: '9',
    user: {
      firstname: 'Adolf',
      lastname: 'Fodal',
      userId: 'fallback24'
    },
    reference: 'as wrt wetrcasdcasdcasdc',
    costCenter: 'Costcenter 9',
    due: '2025-05-12',
    status: InvoiceStatus.Draft,
  },
  {
    id: '10',
    user: {
      firstname: 'Ulf',
      lastname: 'Ulfinger',
      userId: 'fallback25'
    },
    reference: 'a  wefawe  f  aoehf apoewhfawpoehf aowue fdc',
    costCenter: 'Costcenter 10',
    due: '2024-06-12',
    status: InvoiceStatus.New,
  },
  {
    id: '11',
    user: {
      firstname: 'Nina',
      lastname: 'Mustermann',
      userId: 'fallback11'
    },
    reference: '3245 2345 Lara lara lara',
    costCenter: 'Costcenter 11',
    due: '2021-07-22',
    status: InvoiceStatus.Overdue,
  },
  {
    id: '12',
    user: {
      firstname: 'Lara',
      lastname: 'Tester',
      userId: 'fallback12'
    },
    reference: 'asdfa wtrw sdf lara lara',
    costCenter: 'Costcenter 12',
    due: '2023-05-03',
    status: InvoiceStatus.Sent,
  },
  {
    id: '13',
    user: {
      firstname: 'Paulinschen',
      lastname: 'Asdf',
      userId: 'fallback13'
    },
    reference: 'xcvbxvcb ascdasdcasdc lara lara',
    costCenter: 'Costcenter 13',
    due: '2025-05-02',
    status: InvoiceStatus.Paid,
  },
  {
    id: '14',
    user: {
      firstname: 'Adolf',
      lastname: 'Fodal',
      userId: 'fallback14'
    },
    reference: 'asdfasdf ascasdcasdcasdc',
    costCenter: 'Costcenter 14',
    due: '2025-04-07',
    status: InvoiceStatus.Open,
  },
  {
    id: '15',
    user: {
      firstname: 'Ulf',
      lastname: 'Ulfinger',
      userId: 'fallback15'
    },
    reference: 'yxcv awefawef  aoehf apoewhfawpoehf aowue fdc',
    costCenter: 'Costcenter 15',
    due: '2024-05-02',
    status: InvoiceStatus.New,
  },
];
